const isEmpty = (value: unknown) => {
  if (value === undefined) {
    return true;
  }
  if (value === null) {
    return true;
  }
  if (value === '') {
    return true;
  }
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }
  return false;
};

export const isEmptyObject = (obj: Record<string, unknown>) => {
  const values = Object.values(obj);
  if (values.some((value) => !isEmpty(value))) {
    return false;
  }
  return true;
};
