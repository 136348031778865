import { CookieValueTypes, getCookie, removeCookies, setCookie } from 'cookies-next';
import { GetServerSidePropsContext } from 'next';

import { addDays } from '@/utils/time.utils';

import { SELECTED_CAR_CONFIG_COOKIE_KEY } from './selectCar.constants';
import { SelectCarValue, SelectCarValueWithSize } from './selectCar.types';

function isSelectCarType(data: any): data is SelectCarValueWithSize {
  //TODO add zod
  return (
    'producer' in data &&
    'model' in data &&
    'year' in data &&
    'generation' in data &&
    'engine' in data &&
    'size' in data &&
    'sizeItemId' in data
  );
}

const parseSelectCarCookie = (str: CookieValueTypes) => {
  if (typeof str !== 'string') {
    return null;
  }
  try {
    const value = JSON.parse(str);
    if (isSelectCarType(value)) {
      return value;
    }
  } catch {
    return null;
  }
  return null;
};

export const setSelectCarCookie = (data: SelectCarValueWithSize) => {
  setCookie(SELECTED_CAR_CONFIG_COOKIE_KEY, data, {
    expires: addDays(new Date(), 7),
  });
};

export const removeSelectCarCookie = () => {
  removeCookies(SELECTED_CAR_CONFIG_COOKIE_KEY);
};

export const getSelectCarCookie = (context: GetServerSidePropsContext) => {
  const cookie = parseSelectCarCookie(getCookie(SELECTED_CAR_CONFIG_COOKIE_KEY, context));
  return cookie;
};

export const isSelectCarFull = (value: SelectCarValue | null): value is SelectCarValueWithSize => {
  if (!value) {
    return false;
  }
  if (value.engine && value.generation && value.model && value.producer && value.year) {
    return true;
  }
  return false;
};
