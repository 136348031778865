import React, { ChangeEvent, ReactNode, useCallback } from 'react';

import { Icon } from '@/components/_atoms/Icon';
import { Typography } from '@/components/_atoms/Typography';
import { cn } from '@/utils/styles.utils';

type CheckboxSize = 's' | 'm';

export interface CheckboxProps {
  size?: CheckboxSize;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isError?: boolean;
  bold?: boolean;
  label?: string | ReactNode;
  count?: number;
  name: string;
  required?: boolean;
  children?: React.ReactNode;
}

const mapSizeToIconSize: Record<CheckboxSize, number> = {
  s: 16,
  m: 20,
} as const;

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>((props: CheckboxProps, ref) => {
  const {
    checked,
    required,
    count,
    onChange,
    isError,
    bold = false,
    size = 'm',
    label,
    disabled = false,
    name,
    children,
  } = props;
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      onChange?.(event);
    },
    [onChange],
  );

  const RequiredText = required ? (
    <Typography as="span" variant="body_m" color="red" className="ml-0.5">
      {'*'}
    </Typography>
  ) : null;

  const leadingClassName = cn('text-text-grey-standard transition-colors', {
    'text-text-grey-light': disabled,
    'text-text-red': isError,
    'leading-4': size === 's',
    'leading-5': size === 'm',
  });

  return (
    <label
      onKeyDown={(e) => e.stopPropagation()}
      onClick={(e) => e.stopPropagation()}
      className={cn('group inline-flex cursor-pointer', {
        'pointer-events-none': disabled,
      })}
    >
      <span
        className={cn(
          'inline-flex items-center justify-center rounded border border-grey-500 bg-white transition-colors group-hover:border-grey-600',
          {
            'size-4': size === 's',
            'size-5': size === 'm',
            'border-grey-400 bg-grey-300': disabled,
            'border-grey-1000 bg-grey-1000 group-hover:border-grey-800 group-hover:bg-grey-800': checked,
            'border-red-400 group-hover:border-red-500': isError && !checked,
            'border-red-400 bg-red-400 group-hover:border-red-500 group-hover:bg-red-500': isError && checked,
          },
        )}
      >
        <Icon
          className={cn('scale-0 fill-white transition-all', {
            'fill-grey-600': disabled,
            'scale-100': checked,
          })}
          name="tick"
          size={mapSizeToIconSize[size]}
        />
      </span>
      {children ? (
        <div
          className={cn('ml-2 flex items-center justify-center', {
            'leading-4': size === 's',
            'leading-5': size === 'm',
          })}
        >
          {children}
        </div>
      ) : null}
      {label && (
        <Typography as="span" variant="body_m" className={cn('ml-2', leadingClassName)} bold={bold}>
          {label}
          {RequiredText}
        </Typography>
      )}
      {label && typeof count === 'number' && (
        <Typography as="span" variant="body_s" className={cn('ml-1', leadingClassName)} bold={bold}>
          ({count})
        </Typography>
      )}
      <input
        ref={ref}
        type="checkbox"
        name={name}
        className="sr-only"
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      />
    </label>
  );
});

Checkbox.displayName = 'Checkbox';
