import { useShallow } from 'zustand/react/shallow';

import { useAppStore } from '@/store';

import { SelectCarValueWithSize } from './selectCar.types';
import { removeSelectCarCookie, setSelectCarCookie } from './selectCar.utils';

export const useCar = () => {
  const { selectedCar, setSelectedCar } = useAppStore(
    useShallow((store) => ({
      selectedCar: store.selectedCar,
      setSelectedCar: store.setSelectedCar,
    })),
  );

  const selectCar = (data: SelectCarValueWithSize) => {
    setSelectedCar(data);
    setSelectCarCookie(data);
  };

  const removeCar = () => {
    setSelectedCar(null);
    removeSelectCarCookie();
  };

  return {
    selectedCar,
    selectCar,
    removeCar,
  };
};
