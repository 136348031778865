import { useCarSearchSizesQuery } from '@/network';

import { SelectCarValueRequired } from './selectCar.types';

export const useSelectCarSizes = (data: SelectCarValueRequired) => {
  const query = useCarSearchSizesQuery(data.producer, data.model, data.year, data.generation, data.engine);

  return {
    carData: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
  };
};
