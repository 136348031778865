export const TYRE_SEARCH_DEFAULT_WIDTH = '205' as const;
export const TYRE_SEARCH_DEFAULT_PROFILE = '55' as const;
export const TYRE_SEARCH_DEFAULT_RIM = '16' as const;
export const FILTER_PRODUCER_ID = 'producent' as const;
export const FILTER_SEASON_ID = 'sezon' as const;
export const FILTER_SIZE_ID = 'rozmiar' as const;
export const FILTER_TYRE_CLASS_ID = 'klasa' as const;
export const FILTER_PROMOTION_ID = 'promocja' as const;
export const FILTER_EAN_ID = 'ean' as const;
export const FILTER_MODEL_ID = 'model' as const;
export const FILTER_RIM_ID = 'srednica' as const;
export const TYRE_DEFAULT_SIZE = '205/55R16' as const;
export interface TyreSearchFilters {
  [FILTER_SEASON_ID]: string;
  [FILTER_PRODUCER_ID]: string[];
  [FILTER_SIZE_ID]: string;
}

export interface TyreSize {
  width: string;
  profile: string;
  rim: string;
}

export type TyreSearchAllFilters = TyreSearchFilters & { page?: string };

export type FilterPromotionId = typeof FILTER_PROMOTION_ID;
export type FilterSizeId = typeof FILTER_SIZE_ID;
